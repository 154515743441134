// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactdetails_top_line__XL2A- {
  border-top: 1px solid rgba(145, 158, 171, 0.24);
  padding-top: 15px;
}

.contactdetails_phone__3pvWm {
  display: flex;
  align-items: center;
}
.contactdetails_phone__3pvWm > img {
  height: 36px;
  width: 36px;
  margin-right: 15px;
}
.contactdetails_phone__3pvWm > p {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #212b36;
}`, "",{"version":3,"sources":["webpack://./src/components/contactDetailsModal/contactdetails.module.scss"],"names":[],"mappings":"AAAA;EACI,+CAAA;EACA,iBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;AACJ;AAAI;EACI,YAAA;EACA,WAAA;EACA,kBAAA;AAER;AAAI;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAER","sourcesContent":[".top_line{\n    border-top: 1px solid rgba(145, 158, 171, 0.24);\n    padding-top: 15px;\n}\n\n.phone {\n    display: flex;\n    align-items: center;\n    > img {\n        height: 36px;\n        width: 36px;\n        margin-right: 15px;\n    }\n    > p {\n        font-weight: 600;\n        font-size: 14px;\n        line-height: 24px;\n        color: #212b36;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top_line": `contactdetails_top_line__XL2A-`,
	"phone": `contactdetails_phone__3pvWm`
};
export default ___CSS_LOADER_EXPORT___;
