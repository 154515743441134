// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_header__MhatS {
  height: 80px;
  background-color: #f7f8f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}
.header_header__MhatS .header_page_name__EoEau {
  width: 30%;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  color: #212b36;
  flex-shrink: 0;
}
.header_header__MhatS .header_img_container__eXFir {
  width: 40%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.header_header__MhatS .header_img_container__eXFir > img {
  margin: 0 auto;
  width: 214px;
  height: 35px;
}
.header_header__MhatS .header_login_wrap__pcUix {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
}
.header_header__MhatS .header_login_wrap__pcUix > button {
  border: none;
  background: none;
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
}
.header_header__MhatS .header_login_wrap__pcUix > button:hover {
  /* text-decoration: underline; */
  color: #3366ff;
}`, "",{"version":3,"sources":["webpack://./src/components/header/header.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,yBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,iBAAA;AACF;AACE;EACE,UAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,cAAA;AACJ;AAEE;EACE,UAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AAAJ;AAEI;EACE,cAAA;EACA,YAAA;EACA,YAAA;AAAN;AAIE;EACE,UAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,cAAA;AAFJ;AAGI;EACE,YAAA;EACA,gBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;EACA,mBAAA;AADN;AAEM;EACE,gCAAA;EACA,cAAA;AAAR","sourcesContent":[".header {\n  height: 80px;\n  background-color: #f7f8f9;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0px 20px;\n\n  .page_name {\n    width: 30%;\n    font-weight: 600;\n    font-size: 22px;\n    line-height: 22px;\n    color: #212b36;\n    flex-shrink: 0;\n  }\n\n  .img_container {\n    width: 40%;\n    display: flex;\n    align-items: center;\n    flex-shrink: 0;\n\n    >img {\n      margin: 0 auto;\n      width: 214px;\n      height: 35px;\n    }\n  }\n\n  .login_wrap {\n    width: 30%;\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    flex-shrink: 0;\n    >button {\n      border: none;\n      background: none;\n      padding: 0px;\n      margin: 0px;\n      font-size: 14px;\n      cursor: pointer;\n      white-space: nowrap;\n      &:hover {\n        /* text-decoration: underline; */\n        color: #3366ff;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_header__MhatS`,
	"page_name": `header_page_name__EoEau`,
	"img_container": `header_img_container__eXFir`,
	"login_wrap": `header_login_wrap__pcUix`
};
export default ___CSS_LOADER_EXPORT___;
