/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { carrierIcon } from '../../assets/svg/carrier';
import '@trimblemaps/trimblemaps-js/trimblemaps.css';

const TrimbleMap = ({
  width,
  height,
  routes,
  popupContent,
  markerPopup,
  onLaneClick,
  consignee_coordinates_long,
  consignee_coordinates_lat,
  shipper_coordinates_long,
  shipper_coordinates_lat
}) => {
  const [myRoutes, setMyRoutes] = useState(null);
  const [myRoutesOptions, setMyRoutesOptions] = useState(null);
  const [activeRoute, setActiveRoute] = useState(null);

  const myMap = useRef(null);
  const mouseLangLat = useRef(null);

  useEffect(() => {
    if (TrimbleMaps) {
      TrimbleMaps.APIKey = "2D12936544A8B740B83EBE93EE4691BB";

      const map = new TrimbleMaps.Map({
        container: "myMap",
        center: new TrimbleMaps.LngLat(-96, 35),
        zoom: 3
      });

      map.on('mousemove', (e) => { mouseLangLat.current = e.lngLat });

      myMap.current = map;
    }
  }, [routes]);
  
  useEffect(() => {
    if (routes && routes.length > 0) {
      const allRoutes = [];
      let allRoutesOptions = routes;

      for (let x = 0; x < routes.length; x += 1) {
        const consigneeLong = routes[x].consignee_coordinates_long || consignee_coordinates_long;
        const consigneeLat = routes[x].consignee_coordinates_lat || consignee_coordinates_lat;
        const shipperLong = routes[x].shipper_coordinates_long || shipper_coordinates_long;
        const shipperLat = routes[x].shipper_coordinates_lat || shipper_coordinates_lat;

        const stops = [];
        stops.push(new TrimbleMaps.LngLat(consigneeLong, consigneeLat));
        /* if (routes[x].stops && routes[x].stops.length > 0) {
          for (let y = 0; y < routes[x].stops.length; y += 1) {
            if (routes[x].stops[y]?.latlong) {
              const latLongArr = routes[x].stops[y]?.latlong.split(',');
              stops.push(new TrimbleMaps.LngLat(latLongArr[1], latLongArr[0]));
            }
          }
        } */
        stops.push(new TrimbleMaps.LngLat(shipperLong, shipperLat));

        allRoutesOptions[x].stops = stops;

        // delete allRoutesOptions[x].consignee_coordinates_long;
        // delete allRoutesOptions[x].consignee_coordinates_lat;
        // delete allRoutesOptions[x].shipper_coordinates_long;
        // delete allRoutesOptions[x].shipper_coordinates_lat;

        const newRoute = new TrimbleMaps.Route(allRoutesOptions[x]);

        /* newRoute.on('mouseenter', () => {
          if (allRoutes && allRoutes[x] && allRoutesOptions && allRoutesOptions[x]) {
            allRoutesOptions[x].routeColor = '#5932EA';
            allRoutesOptions[x].originIcon = {
              size: 0.2,
              url: upArrow(),
              offset: [0, 0]
            };
            allRoutesOptions[x].destinationIcon = {
              size: 0.2,
              url: downArrow(),
              offset: [0, 0]
            };
            allRoutes[x].update(allRoutesOptions[x]);
          }
        }); */

        /* newRoute.on('mouseleave', () => {
          if (allRoutes && allRoutes[x] && allRoutesOptions && allRoutesOptions[x]) {
            allRoutesOptions[x].routeColor = '#000';
            allRoutesOptions[x].originIcon = {
              size: 0.2,
              url: upArrow(),
              offset: [0, 0]
            };
            allRoutesOptions[x].destinationIcon = {
              size: 0.2,
              url: downArrow(),
              offset: [0, 0]
            };
            allRoutes[x].update(allRoutesOptions[x]);
          }
        }); */

        newRoute.on('click', () => {
          if (popupContent) {
            new TrimbleMaps.Popup()
              .setLngLat(new TrimbleMaps.LngLat(mouseLangLat.current.lng, mouseLangLat.current.lat))
              .setHTML(popupContent(x))
              .addTo(myMap.current);
          }

          if (onLaneClick) {
            onLaneClick(x);
          }
        });

        allRoutes.push(newRoute);
      }

      setMyRoutes(allRoutes);
      setMyRoutesOptions(allRoutesOptions);

      myMap.current.on('load', function() {
        if (allRoutes && allRoutes.length > 0) {
          for (let x = 0; x < allRoutes.length; x += 1) {
            try {
              console.log('all routes =====>', allRoutes[x])
              allRoutes[x].addTo(myMap.current);
            } catch {
              //
            }

            if (routes[x].carriers && routes[x].carriers.length > 0) {
              for (let y = 0; y < routes[x].carriers.length; y += 1) {
                // console.log('carriers =====>', routes[x].carriers);
                if (routes[x].carriers[y]) {
                  const latLongArr = routes[x].carriers[y]?.latlong.split(',');
                  // stops.push(new TrimbleMaps.LngLat(latLongArr[1], latLongArr[0]));
                  
                  const mainDiv = document.createElement('div');
                  mainDiv.style = 'display: flex; flex-direction: column; align-items: center;';
                  const svgContent = document.createElement('div');
                  svgContent.style.height = '34px';
                  const content = carrierIcon();
                  svgContent.innerHTML = content;
                  const carrierCount = document.createElement('div');
                  carrierCount.style = 'font-size: 12px;'
                  carrierCount.innerHTML = routes[x].carriers[y].total_trucks || '';
                  mainDiv.appendChild(svgContent);
                  mainDiv.appendChild(carrierCount);

                  const popup = new TrimbleMaps.Popup({
                    offset: 30
                  })
                  .setHTML(markerPopup(x, y))
                  .setMaxWidth("350px");

                  const marker = new TrimbleMaps.Marker({
                    // draggable: true,
                    element: mainDiv
                  })
                  .setPopup(popup)
                  .setLngLat([latLongArr[1], latLongArr[0]]).addTo(myMap.current);
                }
              }
            }
          }
        }
      });
    }
  }, [routes]);
  
  return (
    <div id="myMap" style={{ width, height }}></div>
  );
};

export default TrimbleMap;
