// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsemodal_top_line__4cwPQ {
  border-bottom: 1px solid rgba(145, 158, 171, 0.24);
  padding: 25px;
  color: green;
  line-height: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
}
.responsemodal_top_line__4cwPQ > div {
  width: 100%;
}

.responsemodal_phone__AXDEL {
  display: flex;
  align-items: center;
}
.responsemodal_phone__AXDEL > img {
  height: 36px;
  width: 36px;
  margin-right: 15px;
}
.responsemodal_phone__AXDEL > p {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #212b36;
}

.responsemodal_bid_btn__fB-64 {
  background: #212b36;
  border-radius: 8px;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
  width: 120px;
  height: 48px;
  border: none;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/ResponseModal/responsemodal.module.scss"],"names":[],"mappings":"AAAA;EACI,kDAAA;EACA,aAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,qBAAA;AACJ;AACI;EACI,WAAA;AACR;;AAGA;EACI,aAAA;EACA,mBAAA;AAAJ;AACI;EACI,YAAA;EACA,WAAA;EACA,kBAAA;AACR;AACI;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACR;;AAEA;EACI,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;AACJ","sourcesContent":[".top_line {\n    border-bottom: 1px solid rgba(145, 158, 171, 0.24);\n    padding: 25px;\n    color: green;\n    line-height: 30px;\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    align-content: center;\n    // width: 100%;\n    >div{\n        width: 100%;\n    }\n}\n\n.phone {\n    display: flex;\n    align-items: center;\n    > img {\n        height: 36px;\n        width: 36px;\n        margin-right: 15px;\n    }\n    > p {\n        font-weight: 600;\n        font-size: 14px;\n        line-height: 24px;\n        color: #212b36;\n    }\n}\n.bid_btn {\n    background: #212b36;\n    border-radius: 8px;\n    font-weight: 700;\n    font-size: 15px;\n    line-height: 22px;\n    color: #ffffff;\n    width: 120px;\n    height: 48px;\n    border: none;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top_line": `responsemodal_top_line__4cwPQ`,
	"phone": `responsemodal_phone__AXDEL`,
	"bid_btn": `responsemodal_bid_btn__fB-64`
};
export default ___CSS_LOADER_EXPORT___;
