// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmationmodal_top_line__AWioE {
  padding: 10px;
  line-height: 30px;
  text-align: center;
}
.confirmationmodal_top_line__AWioE > div {
  width: 100%;
}
.confirmationmodal_top_line__AWioE > div:last-child {
  margin-top: 15px;
}

.confirmationmodal_phone__U5Ejm {
  display: flex;
  align-items: center;
}
.confirmationmodal_phone__U5Ejm > img {
  height: 36px;
  width: 36px;
  margin-right: 15px;
}
.confirmationmodal_phone__U5Ejm > p {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #212b36;
}

.confirmationmodal_bid_btn__PYvUx {
  background: #212b36;
  border-radius: 8px;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
  height: 35px;
  border: none;
  cursor: pointer;
  padding: 0px 20px;
}

.confirmationmodal_cancel_btn__DGBk1 {
  background: #fff;
  border-radius: 8px;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #212b36;
  height: 35px;
  border: none;
  cursor: pointer;
  padding: 0px 20px;
  border: 1px solid #212b36;
}`, "",{"version":3,"sources":["webpack://./src/components/confirmationModal/confirmationmodal.module.scss"],"names":[],"mappings":"AAAA;EAGI,aAAA;EACA,iBAAA;EACA,kBAAA;AADJ;AAGI;EACI,WAAA;AADR;AAEI;EACI,gBAAA;AAAR;;AAKA;EACI,aAAA;EACA,mBAAA;AAFJ;AAGI;EACI,YAAA;EACA,WAAA;EACA,kBAAA;AADR;AAGI;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADR;;AAIA;EACI,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EAEA,YAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;AAFJ;;AAKA;EACI,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EAEA,YAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;AAHJ","sourcesContent":[".top_line {\n    // border-top: 1px solid rgba(145, 158, 171, 0.24);\n    // border-bottom: 1px solid rgba(145, 158, 171, 0.24);\n    padding: 10px;\n    line-height: 30px;\n    text-align: center;\n    // width: 100%;\n    >div{\n        width: 100%;\n    &:last-child{\n        margin-top: 15px;\n    }\n    }\n}\n\n.phone {\n    display: flex;\n    align-items: center;\n    > img {\n        height: 36px;\n        width: 36px;\n        margin-right: 15px;\n    }\n    > p {\n        font-weight: 600;\n        font-size: 14px;\n        line-height: 24px;\n        color: #212b36;\n    }\n}\n.bid_btn {\n    background: #212b36;\n    border-radius: 8px;\n    font-weight: 700;\n    font-size: 15px;\n    line-height: 22px;\n    color: #ffffff;\n    // width: 120px;\n    height: 35px;\n    border: none;\n    cursor: pointer;\n    padding: 0px 20px;\n}\n\n.cancel_btn {\n    background: #fff;\n    border-radius: 8px;\n    font-weight: 700;\n    font-size: 15px;\n    line-height: 22px;\n    color: #212b36;\n    // width: 120px;\n    height: 35px;\n    border: none;\n    cursor: pointer;\n    padding: 0px 20px;\n    border: 1px solid #212b36;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top_line": `confirmationmodal_top_line__AWioE`,
	"phone": `confirmationmodal_phone__U5Ejm`,
	"bid_btn": `confirmationmodal_bid_btn__PYvUx`,
	"cancel_btn": `confirmationmodal_cancel_btn__DGBk1`
};
export default ___CSS_LOADER_EXPORT___;
