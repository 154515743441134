export const carrierType = [
  { key: "Flatbed", value: "FLATBED" },
  { key: "Reefer", value: "REEFER" },
  { key: "Van", value: "VAN" },
  { key: "Any type", value: "ANYTYPE" }
];

export const dhp = [
  { key: 50, value: 50 },
  { key: 100, value: 100 },
  { key: 150, value: 150 },
  { key: 200, value: 200 }
];

export const distanceList = [
  { key: 100, value: 100 },
  { key: 200, value: 200 },
  { key: 500, value: 500 },
  { key: 1000, value: 1000 }
];
