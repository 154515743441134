// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bidmodal_top_line__-qXxW {
  border-top: 1px solid rgba(145, 158, 171, 0.24);
  border-bottom: 1px solid rgba(145, 158, 171, 0.24);
  padding: 25px 0;
}
.bidmodal_top_line__-qXxW > div {
  width: 100%;
}
.bidmodal_top_line__-qXxW > div:first-child {
  margin-bottom: 15px;
}

.bidmodal_phone__DwpCa {
  display: flex;
  align-items: center;
}
.bidmodal_phone__DwpCa > img {
  height: 36px;
  width: 36px;
  margin-right: 15px;
}
.bidmodal_phone__DwpCa > p {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #212b36;
}

.bidmodal_bid_btn__5-NBz {
  background: #212b36;
  border-radius: 8px;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
  width: 120px;
  height: 48px;
  border: none;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/bidModal/bidmodal.module.scss"],"names":[],"mappings":"AAAA;EACI,+CAAA;EACA,kDAAA;EACA,eAAA;AACJ;AACI;EACI,WAAA;AACR;AAAI;EACI,mBAAA;AAER;;AAGA;EACI,aAAA;EACA,mBAAA;AAAJ;AACI;EACI,YAAA;EACA,WAAA;EACA,kBAAA;AACR;AACI;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACR;;AAEA;EACI,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;AACJ","sourcesContent":[".top_line {\n    border-top: 1px solid rgba(145, 158, 171, 0.24);\n    border-bottom: 1px solid rgba(145, 158, 171, 0.24);\n    padding: 25px 0;\n    // width: 100%;\n    >div{\n        width: 100%;\n    &:first-child{\n        margin-bottom: 15px;\n    }\n    }\n}\n\n.phone {\n    display: flex;\n    align-items: center;\n    > img {\n        height: 36px;\n        width: 36px;\n        margin-right: 15px;\n    }\n    > p {\n        font-weight: 600;\n        font-size: 14px;\n        line-height: 24px;\n        color: #212b36;\n    }\n}\n.bid_btn {\n    background: #212b36;\n    border-radius: 8px;\n    font-weight: 700;\n    font-size: 15px;\n    line-height: 22px;\n    color: #ffffff;\n    width: 120px;\n    height: 48px;\n    border: none;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top_line": `bidmodal_top_line__-qXxW`,
	"phone": `bidmodal_phone__DwpCa`,
	"bid_btn": `bidmodal_bid_btn__5-NBz`
};
export default ___CSS_LOADER_EXPORT___;
