import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./contactdetails.module.scss";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    background: "#FFFFFF",
    borderRadius: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    background: "#FFFFFF",
    borderRadius: "16px",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      fontSize={18}
      fontWeight={700}
      sx={{ m: 0, p: 2 }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function CustomizedDialogs({
  open,
  setOpen,
  handleClickOpen,
  handleClose,
  contact,
}) {

  return (
    <div>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper ": {
            width: "370px",
            borderRadius: "16px",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          FreightX Operations Contact
        </BootstrapDialogTitle>
        <DialogContent>
          <div className={styles.top_line}>
            <div className={styles.phone}>
              <img src="images/call.svg" alt="" />
              <p>{contact}</p>
            </div>
            <div className={styles.phone}>
              <img src="images/mail.svg" alt="" />
              <p>support@freightx.com</p>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
