// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forgotPassSuccess_background__qsbWS {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
}
.forgotPassSuccess_background__qsbWS .forgotPassSuccess_container__Jh0UZ {
  height: 100vh;
  width: 35%;
  background-color: white;
  float: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.forgotPassSuccess_background__qsbWS .forgotPassSuccess_container__Jh0UZ > div {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 10%;
  align-items: center;
}
.forgotPassSuccess_background__qsbWS .forgotPassSuccess_container__Jh0UZ > div > p {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #212b36;
  margin-bottom: 10px;
}
.forgotPassSuccess_background__qsbWS .forgotPassSuccess_container__Jh0UZ > div .forgotPassSuccess_footer_text__iAlSo {
  font-weight: 400;
  margin-top: 0;
  font-size: 14px;
  line-height: 22px;
}
.forgotPassSuccess_background__qsbWS .forgotPassSuccess_container__Jh0UZ > div .forgotPassSuccess_footer_text__iAlSo > span {
  text-decoration: none;
  color: #3366ff;
}
.forgotPassSuccess_background__qsbWS .forgotPassSuccess_container__Jh0UZ > div .forgotPassSuccess_footer_text__iAlSo > span:hover {
  cursor: pointer;
  text-decoration: underline;
}
.forgotPassSuccess_background__qsbWS > img {
  padding: 58px;
}`, "",{"version":3,"sources":["webpack://./src/components/forgotPassSuccess/forgotPassSuccess.module.scss"],"names":[],"mappings":"AAAA;EACI,yDAAA;EACA,aAAA;EACA,YAAA;EACA,4BAAA;EACA,sBAAA;AACJ;AACI;EACI,aAAA;EACA,UAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACR;AAAQ;EACI,aAAA;EACA,sBAAA;EACA,UAAA;EACA,YAAA;EACA,mBAAA;AAEZ;AADY;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EAEA,kBAAA;EACA,cAAA;EACA,mBAAA;AAEhB;AAAY;EACI,gBAAA;EACA,aAAA;EACA,eAAA;EACA,iBAAA;AAEhB;AAAgB;EACI,qBAAA;EACA,cAAA;AAEpB;AAAgB;EACI,eAAA;EACA,0BAAA;AAEpB;AAGI;EACI,aAAA;AADR","sourcesContent":[".background {\n    background-image: url(\"../../../public/images/logo.svg\");\n    height: 100vh;\n    width: 100vw;\n    background-repeat: no-repeat;\n    background-size: cover;\n\n    .container {\n        height: 100vh;\n        width: 35%;\n        background-color: white;\n        float: right;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        justify-content: center;\n        > div {\n            display: flex;\n            flex-direction: column;\n            width: 80%;\n            padding: 10%;\n            align-items: center;\n            > p {\n                font-weight: 600;\n                font-size: 24px;\n                line-height: 32px;\n\n                text-align: center;\n                color: #212b36;\n                margin-bottom: 10px;\n            }\n            .footer_text {\n                font-weight: 400;\n                margin-top: 0;\n                font-size: 14px;\n                line-height: 22px;\n\n                > span {\n                    text-decoration: none;\n                    color: #3366ff;\n                }\n                >span:hover{\n                    cursor: pointer;\n                    text-decoration: underline;\n                }\n            }\n        }\n    }\n    > img {\n        padding: 58px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `forgotPassSuccess_background__qsbWS`,
	"container": `forgotPassSuccess_container__Jh0UZ`,
	"footer_text": `forgotPassSuccess_footer_text__iAlSo`
};
export default ___CSS_LOADER_EXPORT___;
