/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginModal from "../../components/loginModal";
import { isValidToken } from "../../utils/utils";
import styles from "./header.module.scss";
import AccountPopover from "./AccountPopover";

const Header = ({ showLogin, hideLogin }) => {
  const [openLogin, setOpenLogin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (openLogin === false && showLogin === true) {
      setOpenLogin(true);
    }
  }, [showLogin]);

  return (
    <div>
      <LoginModal
        handleClose={() => {
          setOpenLogin(false);
          if (hideLogin) hideLogin();
        }}
        open={openLogin}
      />

      <div className={styles.header}>
        <p className={styles.page_name}>Loads</p>
        <div className={styles.img_container}>
          <img src="images/freightx_head_dark.svg" alt="" />
        </div>

        { isValidToken() ?
          <div className={styles.login_wrap}>
            <AccountPopover />
          </div>
        :
          <div className={styles.login_wrap}>
            <button
              onClick={() => setOpenLogin(true)}
              style={{ marginRight: '25px' }}
            >Sign In</button>
            <button
              onClick={() => navigate("/signup")}
            >Create an account</button>
          </div>
        }
      </div>
    </div>
  )
}

export default Header;
