import {
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "./forgotPass.module.scss";
import ForgotPassSuccess from "../../components/forgotPassSuccess/forgotPassSuccess";
import { useNavigate } from "react-router-dom";

const ForgotPass = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [successAccount, setSuccessAccount] = useState(false);
    const [error, setError] = useState();
    const [message,setMessage]=useState()
    const navigate=useNavigate()
    let mobileregex = new RegExp("\\d{10}$");

    const [carrierData, setCarrierData] = useState({
        phone: '',
        password: '',
        verify_code: '',
        cnfrm_password: '',
    });
    const [carrierDataError, setCarrierDataError] = useState({
        phone: null,
        password: null,
        verify_code: null,
        cnfrm_password: null,
    });
    const handleClickShowPassword = () => setShowPassword((show) => !show);

     useEffect(() => {
        const getToken = localStorage.getItem("accessToken");
        if (getToken) {
            navigate(`/`, { replace: true });
        }
    } , []);
    const getVerificationCode = async () => {
        if((carrierData.phone!==''||carrierData.phone!==null)&&mobileregex.test(carrierData.phone)){
            const response = await axios.post(
                `${process.env.REACT_APP_API_PATH}auth/reset-code`,
                { phone: carrierData.phone }
            );
            if(response.data.error){
                setError(response.data.error)
            }else{
                setError(null)

            }
                        console.log('-----',response.data.error);

        }else{
            setError("Enter valid phone number")
        }
    }
    const updatePassword = async () => {
        try {
            if (carrierData.cnfrm_password != carrierData.password) {
                setError("Password donot match");
            }else{
                setError(null)
            }

            if (carrierData.phone === "" || carrierData.phone === null) {
                setCarrierDataError((prev) => {
                    return { ...prev, phone: "Register phone is required" };
                });
            } else {
                let regex = new RegExp("\\d{10}$");
                if (!regex.test(carrierData.phone)) {
                    setCarrierDataError((prev) => {
                        return { ...prev, phone: "Enter valid phone number" };
                    });
                } else {
                    setCarrierDataError((prev) => {
                        return { ...prev, phone: null };
                    });
                }
            }


            if (
                carrierData.verify_code === "" ||
                carrierData.verify_code === null
            ) {
                setCarrierDataError((prev) => {
                    return {
                        ...prev,
                        verify_code: "Verification code is required",
                    };
                });
            }else{
                setCarrierDataError((prev) => {
                    return {
                        ...prev,
                        verify_code: null,
                    };
                });
            }
            if (
                carrierData.cnfrm_password === "" ||
                carrierData.cnfrm_password === null
            ) {
                setCarrierDataError((prev) => {
                    return {
                        ...prev,
                        cnfrm_password: "Confirm password is required",
                    };
                });
            }else{
                setCarrierDataError((prev) => {
                    return {
                        ...prev,
                        cnfrm_password: null,
                    };
                });
            }
            if (carrierData.password === "" || carrierData.password === null) {
                setCarrierDataError((prev) => {
                    return { ...prev, password: "Password is required" };
                });
            }else{
                setCarrierDataError((prev) => {
                    return { ...prev, password:null };
                });
            }
            if (
                !carrierDataError.phone &&
                !carrierDataError.password &&
                !carrierDataError.cnfrm_password &&
                !carrierDataError.verify_code
            ) {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_PATH}auth/forgot-password`,
                    {
                        phone: carrierData.phone,
                        password: carrierData.password,
                        verification_code: carrierData.verify_code,
                    }
                );
                console.log(response?.data?.meta.success);
                if (response?.data?.meta.success) {
                    setCarrierData({

                        phone: '',
                        password: '',
                        verify_code: '',
                        cnfrm_password: '',
                    });
                    setSuccessAccount(true)
                }

            }
        } catch (error) {
            // console.log(error);
        }
    };
    return !successAccount ? (
        <div className={styles.background}>
            <img
                src={"images/freightx_head.svg"}
                width={340}
                height={55}
                alt=""
            />
            <div className={styles.container}>
                <div>
                    <p>Forgot Password?</p>
                    <p className={styles.text}>Please provide your registered phone number or <br/> email to get your password reset code.</p>

                    <div className={styles.text_field_container}>
                        <TextField
                            id="outlined-basic"
                            error={carrierDataError.phone ? true : false}
                            label="Registered phone*"
                            variant="outlined"
                            type={"number"}
                            InputLabelProps={{
                                style: {
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    width: "100%",
                                    color: "#919EAB",
                                },
                            }}
                            InputProps={{
                                style: {
                                    borderRadius: "8px",
                                },
                            }}
                            inputProps={{ maxLength: 10 }}
                            onChange={(e) =>
                                setCarrierData((prev) => {
                                    return {
                                        ...prev,
                                        phone: e.target.value.slice(0, 10),
                                    };
                                })
                            }
                            value={carrierData.phone}
                            helperText={
                                carrierDataError.phone
                                    ? carrierDataError.phone
                                    : ""
                            }
                        />
                    </div>



                    <FormControl
                        // sx={{ m: 1, width: "25ch" }}
                        style={{ marginBottom: "20px" }}
                        variant="outlined"
                    >
                        <InputLabel
                            style={{ color: "#919EAB" }}
                            htmlFor="outlined-adornment-password"
                        >
                            6 Digit verification code*
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={"text"}
                            style={{
                                borderRadius: "8px",
                            }}
                            value={carrierData?.verify_code}
                            endAdornment={
                                <InputAdornment position="end">
                                    <div
                                        className={styles.get_code}
                                        onClick={() => getVerificationCode()}
                                    >
                                        Get code
                                    </div>
                                </InputAdornment>
                            }
                            error={carrierDataError.verify_code ? true : false}
                            helperText={
                                carrierDataError.verify_code
                                    ? carrierDataError.verify_code
                                    : ""
                            }
                            label="verification_code"
                            onChange={(e) =>
                                setCarrierData((prev) => {
                                    return {
                                        ...prev,
                                        verify_code: e.target.value,
                                    };
                                })
                            }
                        />
                        {carrierDataError.verify_code && (
                            <p className={styles.carrier_error}>
                                {carrierDataError.verify_code}
                            </p>
                        )}
                    </FormControl>
                    <FormControl
                        // sx={{ }}
                        style={{ marginBottom: "20px" }}
                        variant="outlined"
                    >
                        <InputLabel
                            style={{ color: "#919EAB" }}
                            htmlFor="outlined-adornment-password"
                        >
                            New password*
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            style={{
                                borderRadius: "8px",
                            }}
                            value={carrierData.password}
                            error={carrierDataError.password ? true : false}
                            helperText={
                                carrierDataError.password
                                    ? carrierDataError.password
                                    : ""
                            }
                            onChange={(e) =>
                                setCarrierData((prev) => {
                                    return {
                                        ...prev,
                                        password: e.target.value,
                                    };
                                })
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                        {carrierDataError.password && (
                            <p className={styles.carrier_error}>
                                {carrierDataError.password}
                            </p>
                        )}
                    </FormControl>
                    <FormControl
                        // style={{marginBottom:'25px'}}
                        variant="outlined"
                    >
                        <InputLabel
                            style={{ color: "#919EAB" }}
                            htmlFor="outlined-adornment-password"
                        >
                            Confirm new password*
                        </InputLabel>
                        <OutlinedInput
                            error={
                                carrierDataError.cnfrm_password ? true : false
                            }
                            helperText={
                                carrierDataError.cnfrm_password
                                    ? carrierDataError.cnfrm_password
                                    : ""
                            }
                            value={carrierData.cnfrm_password}
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            style={{ borderRadius: "8px" }}
                            onChange={(e) =>
                                setCarrierData((prev) => {
                                    return {
                                        ...prev,
                                        cnfrm_password: e.target.value,
                                    };
                                })
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Confirm password*"
                        />
                        {carrierDataError.cnfrm_password && (
                            <p className={styles.carrier_error}>
                                {carrierDataError.cnfrm_password}
                            </p>
                        )}
                    </FormControl>

                    {error&&<p className={styles.error}>{error}</p>}

                    <button
                        onClick={() => updatePassword()}
                        className={styles.create_btn}
                    >
                        Submit
                    </button>
                    <p className={`${styles.terms} ${styles.signin}`}>
                        Return to {" "}
                        <span onClick={()=>navigate('/login')}>Sign In!</span>
                    </p>
                </div>
            </div>
        </div>
    ) : (
        <ForgotPassSuccess />
    );
};

export default ForgotPass;
