/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from "@mui/material"
import { pMarker, dMarker } from "../../assets/svg/carrier";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Maps from "../../components/trimbleMap";
import ContactDetails from "../../components/contactDetailsModal";
import BidModal from "../../components/bidModal";
import SavedSearchModal from "../../components/savedSearchModal";
import ConfirmationModal from "../../components/confirmationModal";
import ResponseModal from "../../components/ResponseModal";
import Header from "../../components/header";
import { isValidToken } from "../../utils/utils";
import styles from "./dashboard.module.scss";
import { carrierType, dhp, distanceList } from "../../utils/staticValues";
import { fCurrency } from '../../utils/formatNumber';

const Dashboard = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(null);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [loadsLoading, setLoadsLoading] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);

  const [open, setOpen] = useState();
  const [bookStatus, setBookStatus] = useState(false);
  const [bidModalopen, setBidModalOpen] = useState(false);
  const [bidStatus, setBidStatus] = useState(false);
  const [openContactDetailModal, setContactDetailModalOpen] = useState(false);
  const [activeLoad, setActiveLoad] = useState(null);
  const [savedModal, setSavedModal] = useState(false);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [responseOpen, setResponseOpen] = useState(false);

  const [loadList, setLoadList] = useState(null);
  const [locations, setLocations] = useState([]);
  const [dropLocation, setDropLocation] = useState([]);
  const [pickupLocations,setPickupLocations]=useState([])
  const [filters, setFilters] = useState({
    pickup: 'TURLOCK',
    pickup_city: 'TURLOCK',
    pickup_state: 'CA',
    pickup_dhp: 200,
    pickup_lat: "37.494600",
    pickup_long: "-120.846000",
    // dropoff: 'TURLOCK',
    // dropoff_city: 'TURLOCK',
    // dropoff_state: 'CA',
    // dropoff_dhp: 200,
    // dropoff_lat: "37.494600",
    // dropoff_long: "-120.846000"
  });

  const routes = [{
    routeId: "route01",
    isDraggable: false,
    routeColor: "#5932EA",
    routeWidth: 4,
    // consignee_coordinates_long: -96.928383,
    // consignee_coordinates_lat: 34.716766,
    // shipper_coordinates_long: -96.665239,
    // shipper_coordinates_lat: 30.705186,
    // showStops: false,
    originIcon: {
      size: 0.15,
      url: pMarker(),
      offset: [0, 0],
    },
    destinationIcon: {
      size: 0.15,
      url: dMarker(),
      offset: [0, 0],
    },
  }];

  const columns = [
    /* {
      id: "age",
      label: "Age",
      getValue: (i) => i?.pickup_date ? getAge(i?.pickup_date) : ''
    }, */
    {
      id: "pickup",
      label: "Pick Up",
      getValue: (i) => getLocationText(i, 'pickup')
    },
    { id: "pickupDate",
      label: "Pick Up Date",
      format: (value) => value.toLocaleString("en-US"),
      getValue: (i) => i?.pickup_date ? moment(i.pickup_date).format("MMM, DD") : ''
    },
    {
      id: "dhp",
      label: "DH-P",
      format: (value) => value.toLocaleString("en-US"),
      getValue: (i) => i?.dhp ? Math.round(i?.dhp) : '0'
    },
    {
      id: "dropoff",
      label: "Drop Off",
      format: (value) => value.toFixed(2),
      getValue: (i) => i?.dropoff_city ? capitalize(i.dropoff_city) + " " + (i.dropoff_state ? i.dropoff_state : '') : ''
    },
    { id: "dropoffDate",
      label: "Drop Off Date",
      format: (value) => value.toLocaleString("en-US"),
      getValue: (i) => i?.delivery_date ? moment(i.delivery_date).format("MMM, DD") : ''
    },
    {
      id: "carrierType",
      label: "Trailer Type",
      format: (value) => value.toLocaleString("en-US"),
      getValue: (i) => i.carrier_type ? capitalize(i.carrier_type) : ''
    },
    {
      id: "price",
      label: "Price",
      format: (value) => value.toLocaleString("en-US"),
      getValue: (i) => i.load_price ? fCurrency(Math.round(i.load_price)) : ''
    },
    {
      id: "distance",
      label: "Distance (mi)",
      format: (value) => value.toLocaleString("en-US"),
      getValue: (i) => i.distance ? Math.round(i.distance) : ''
    },
  ];

  useEffect(() => {
    getPickupCities();
    getAllCities();

  }, []);

  useEffect(()=>{
    getDropoffCities(filters.pickup_city);
  },[filters.pickup_city])


  useEffect(() => {
    getAllLoads();
  }, [page, isValidToken(), rowsPerPage]);


  const getAllCities = async () => {
    try {
      let url = `${process.env.REACT_APP_API_PATH_PUBLIC}locations`;
      const response = await axios.get(url);
      setLocations(response?.data?.data?.locations);
    } catch (error) {
    }
  };

  const getPickupCities = async () => {
    try {
      let url = `${process.env.REACT_APP_API_PATH_PUBLIC}loads/get-pickup-locations`;
      const response = await axios.get(url);
      setPickupLocations(response?.data?.data?.locations);
    } catch (error) {
    }
  };

  const getDropoffCities = async (city) => {
    try {
      let url = `${process.env.REACT_APP_API_PATH_PUBLIC}loads/get-dropoff-locations`;
      const response = await axios.post(url,{city:city});
      setDropLocation(response?.data?.data?.locations);
    } catch (error) {
    }
  };

  const getAllLoads = async (filterVal) => {
    setLoadsLoading(true);
    try {
      const urlParams = new URLSearchParams(filterVal ? filterVal : filters).toString();
      let path = process.env.REACT_APP_API_PATH_PUBLIC;
      if (isValidToken()) {
        path = process.env.REACT_APP_API_PATH;
        let accessToken = localStorage.getItem("accessToken");
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      }
      const url = `${path}loads/get-all-loads?offset=${page}&limit=${rowsPerPage}`;
      const response = await axios.get(`${url}${urlParams && urlParams !== '' ? `&${urlParams}` : ''}`);
      if (response?.data?.data) {
        setTotalPages(response?.data?.data?.total);
        setLoadList(response?.data?.data?.loadsData || []);
      }

    } catch (error) {
    }
    setLoadsLoading(false);
    setFiltersLoading(false);
  }

  const createBookedLoad = async (bidCost = 0) => {
    try {
      if (isValidToken() && activeLoad?.id) {
        const accessToken = localStorage.getItem("accessToken");
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        const response = await axios.post(
          `${process.env.REACT_APP_API_PATH}loads/set-booked`,
          {
            bid_cost: bidCost ? parseInt(bidCost, 10) : 0,
            load_id: activeLoad?.id,
          }
        );
        if (response?.data?.meta?.success) {
          setBookStatus(true);
          setResponseOpen(true);
          getAllLoads();
        }
      } else {
        setOpen(true);
      }
    } catch (error) { }
  };

  const getAge = (dt) => {
    let val = '';
    if (dt) {
      const dt2 = new Date();
      const dt1 = new Date(dt);
      let diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60 * 60;
      const diffHours = Math.abs(Math.round(diff));
      if (diffHours > 23) {
        val = <div className={`${styles.day} ${styles.container}`}>{`${Math.round(diffHours / 24).toString()}d`}</div>
      } else {
        val = <div className={`${styles.hour} ${styles.container}`}>{`${diffHours}h`}</div>
      }
    }
    return val;
  }

  const getLatLong = (city,type) => {
    const val = {lat: '34.716766', long: '-96.928383'};
      if (city && city !== '' && locations && locations.length > 0) {
        const loc = locations.filter((l) => l.city === city)?.[0];
        val.lat = loc.latitude || '';
        val.long = loc.longitude || '';
      }
      return val;

  }

  const getLocationText = (i, mode) => {
    let val = '';
    val += capitalize(mode === 'pickup' ? i?.pickup_city : i?.dropoff_city);
    if ((mode === 'pickup' && i?.pickup_state) || (mode === 'dropoff' && i?.dropoff_state)) {
      val += ` ${mode === 'pickup' ? i?.pickup_state : i.dropoff_state}`;
    }
    return val;
  }

  const capitalize = (val) => {
    if (val && val !== '') {
      return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
    } else {
      return '';
    }
  }

  const saveSearchData = () => {
    if (isValidToken()) {
      searchDataSave();
    } else {
      setOpen(true);
    }
  };

  const searchDataSave = async () => {
    // await axios.post(`${process.env.REACT_APP_API_PATH}loads/save-search`, filters);
    const urlParams = new URLSearchParams(filters).toString();
    await axios.get(`${process.env.REACT_APP_API_PATH}lanes/save-lanes?${urlParams && urlParams !== '' ? urlParams : ''}`);
  }

  const handleBidModalOpen = () => {
    if (isValidToken()) {
      setBidModalOpen(true);
    } else {
      setOpen(true);
    }
  };


  return (
    <div>
      <ContactDetails
        open={openContactDetailModal}
        setOpen={setContactDetailModalOpen}
        contact={isValidToken() ? activeLoad?.contact_name : '+XX (XXX) XXX XXXX'}
        handleClickOpen={() => setContactDetailModalOpen(true)}
        handleClose={() => setContactDetailModalOpen(false)}
      />
      <BidModal
        open={bidModalopen}
        setOpen={setBidModalOpen}
        handleClickOpen={handleBidModalOpen}
        handleClose={() => setBidModalOpen(false)}
        setBidStatus={setBidStatus}
        getAllLoads={getAllLoads}
        load_id={activeLoad?.id}
      />
      <SavedSearchModal
        open={savedModal}
        handleClose={() => setSavedModal(false)}
        setActiveLoad={setActiveLoad}
        setFilters={setFilters}
        getAllLoads={getAllLoads}
        setFiltersLoading={setFiltersLoading}
      />
      <ConfirmationModal
        open={confirmOpen}
        handleClose={() => setConfirmOpen(false)}
        confirmationText={activeLoad ? `Would you like to book load from ${capitalize(activeLoad.pickup_city)} ${activeLoad.pickup_state} to ${capitalize(activeLoad.dropoff_city)} ${activeLoad.dropoff_state} on ${activeLoad?.pickup_date ? moment(activeLoad?.pickup_date).format("DD-MMM-YYYY") : '-'}` : ''}
        onConfirm={createBookedLoad}
        confirmText="Yes"
        cancelText={"No"}
      />
      <ResponseModal
        open={responseOpen}
        handleClose={() => setResponseOpen(false)}
        responseText={activeLoad ? `You successfully booked load from ${capitalize(activeLoad.pickup_city)} ${activeLoad.pickup_state} to ${capitalize(activeLoad.dropoff_city)} ${activeLoad.dropoff_state} on ${activeLoad?.pickup_date ? moment(activeLoad?.pickup_date).format("DD-MMM-YYYY") : '-'}` : ''}
      />

      <Header page="Loads" showLogin={open} hideLogin={() => setOpen(false)} />

      { filtersLoading === false ?
        <div className={styles.filter_container}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, pickupdate: moment(e["$d"]).format("YYYY-MM-DD") }))
              }
              className={styles.pick_up_select}
              label="Pick Up Date*"
            />
          </LocalizationProvider>

          <FormControl className={styles.pick_up_select}>
            <InputLabel id="pickup-select-label">Pick Up*</InputLabel>
            <Select
              labelId="pickup-select-label"
              id="pickup-select"
              defaultValue={filters.pickup}
              label="Pick Up*"
              value={filters.pickup}
              onChange={(e) => {
                getDropoffCities(e.target.value)
                const pickupArr = pickupLocations.filter((l) => l.city === e.target.value)?.[0];
                setFilters((prev) => ({
                    ...prev,
                    pickup: pickupArr.city,
                    pickup_city: pickupArr.city,
                    pickup_state: pickupArr.state,
                    pickup_lat: pickupArr.latitude,
                    pickup_long: pickupArr.longitude,
                  })
                );
              }}
            >
              {pickupLocations.map((i) => (
                <MenuItem value={i?.city}>
                  {i?.city?.slice(0, 1) + i?.city?.slice(1, i.city.length)?.toLowerCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={styles.pick_up_select}>
            <InputLabel id="pickup-dhp-select-label">DH-P (mi)</InputLabel>
            <Select
              labelId="pickup-dhp-select-label"
              id="pickup-dhp-select"
              defaultValue={filters.pickup_dhp}
              label="pickup_dhp"
              value={filters.pickup_dhp}
              onChange={(e) =>
                setFilters((prev) => {
                  return { ...prev, pickup_dhp: e.target.value };
                })
              }
            >
              {dhp.map((i) => (
                <MenuItem value={i.value}>{i.key}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, dropoffdate: moment(e["$d"]).format("YYYY-MM-DD") }))
              }
              className={styles.pick_up_select}
              label="Drop Off Date*"
            />
          </LocalizationProvider>

          <FormControl className={styles.pick_up_select}>
            <InputLabel id="dropoff-select-label">Drop Off</InputLabel>
            <Select
              labelId="dropoff-select-label"
              id="dropoff-select"
              label="Drop Off"
              disabled={filters.pickup_city===""?true:false}
              defaultValue={filters.dropoff_city}
              value={filters.dropoff_city}
              onChange={(e) => {
                const dropoffArr = dropLocation.filter((l) => l.city === e.target.value)?.[0];
                setFilters((prev) => ({
                    ...prev,
                    dropoff: dropoffArr.city,
                    dropoff_city: dropoffArr.city,
                    dropoff_state: dropoffArr.state,
                    dropoff_lat: dropoffArr.latitude,
                    dropoff_long: dropoffArr.longitude,
                  })
                );
              }}
            >
              {dropLocation.map((i) => (
                <MenuItem value={i?.city}>
                  {i?.city?.slice(0, 1) + i?.city?.slice(1, i.city.length)?.toLowerCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={styles.pick_up_select}>
            <InputLabel id="dropoff-dhp-select-label">DH-P (mi)</InputLabel>
            <Select
              labelId="dropoff-dhp-select-label"
              id="dropoff-dhp-select"
              label="dropoff_dhp"
              value={filters.dropoff_dhp}
              onChange={(e) =>
                setFilters((prev) => ({...prev, dropoff_dhp: e.target.value}))
              }
            >
              {dhp.map((i) => (
                <MenuItem value={i.value}>{i.key}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={styles.pick_up_select}>
            <InputLabel id="demo-simple-select-label">Trailer Type*</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Trailer type"
              value={filters.carrier_type}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, carrier_type: e.target.value }))
              }
            >
              {carrierType.map((i) => (
                <MenuItem value={i.value}>{i.key}</MenuItem>
              ))}
            </Select>
          </FormControl>

          { showMoreFilters === true ?
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={(e) =>
                  setFilters((prev) => ({ ...prev, dropoffdate: moment(e["$d"]).format("YYYY-MM-DD") }))
                }
                className={styles.pick_up_select}
                label="Delivery Date"
              />
            </LocalizationProvider>
          : ''
          }

          { showMoreFilters === true ?
            <FormControl className={styles.pick_up_select}>
              <InputLabel id="demo-simple-select-label">Distance</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Distance"
                onChange={(e) =>
                  setFilters((prev) => ({...prev, distance: e.target.value}))
                }
              >
                {distanceList.map((i) => (
                  <MenuItem value={i.value}>{i.key}</MenuItem>
                ))}
              </Select>
            </FormControl>
          : ''
          }

          { showMoreFilters === false ?
            <button className={styles.more_filter} onClick={() => setShowMoreFilters(true)}>
              <img src="images/filter.svg" alt="" />
            </button>
          : ''
          }

          <button
            onClick={saveSearchData}
            className={`${styles.save_search_btn} ${filters.pickup && filters.dropoff
              ? `${styles.enable}`
              : `${styles.disable}`
              }`}
          >Save Search</button>
          <button onClick={() => getAllLoads()} className={styles.search_btn}>Search</button>
          <button onClick={() => setSavedModal(true)} className={styles.recent_btn}>
            Recent Lanes<img src="/select-arrow.svg" alt="" />
          </button>
        </div>
      : ''
      }

      <div className={styles.data_container}>
        { loadsLoading ?
          <div className={styles.loading_div}>Loading...</div>
        :
          loadList && loadList.length > 0 ?
            <div className={styles.table_container}>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead sx={{ "& th": { backgroundColor: "#F4F6F8;" } }}>
                      <TableRow selected={true}>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loadList?.map((row) => {
                        return (
                          <TableRow
                            hover
                            onClick={() => {
                              setActiveLoad(row);
                              setBookStatus(false);
                              setBidStatus(false);
                            }}
                            tabIndex={-1}
                            key={row.id}
                            selected={activeLoad && activeLoad?.id === row?.id}
                          >
                            {columns.map((column, columnIndex) => {
                              return (
                                <TableCell key={column.id} align={ column.align }
                                  style={column.id === 'price' ?
                                    {color: '#00AB55', fontWeight: '600', borderLeft: 'none'}
                                  :
                                    {color: 'black', fontWeight: '400', borderLeft: columnIndex === 0 ? `3px solid ${activeLoad && activeLoad?.id === row?.id ? '#5932EA' : '#fff'}` : 'none'}
                                  }
                                >
                                  { column.getValue && column.getValue(row) !== '' ? column.getValue(row) : '-' }
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={totalPages}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(e) => {
                    setRowsPerPage(parseInt(e.target.value, 10));
                    setPage(0);
                  }}
                />
              </Paper>
            </div>
          :
            <div className={styles.no_data_div}>No data found</div>
        }

        { activeLoad ?
          <div className={styles.load_details_container}>
            <div style={{ borderRadius: "8px", overflow: "hidden" }}>
              { activeLoad?.pickup_city && activeLoad?.dropoff_city ?
                <Maps
                  width="100%"
                  height="177px"
                  routes={routes}
                  consignee_coordinates_long={getLatLong(activeLoad?.pickup_city)?.long}
                  consignee_coordinates_lat={getLatLong(activeLoad?.pickup_city)?.lat}
                  shipper_coordinates_long={getLatLong(activeLoad?.dropoff_city)?.long}
                  shipper_coordinates_lat={getLatLong(activeLoad?.dropoff_city)?.lat}
                />
              :
                <div style={{ height: '177px', width: '100%', borderRadius: "8px", background: '#e4e4e4' }} />
              }
            </div>

            <div className={styles.btn_container}>
              <div>
                <button
                  onClick={() => setConfirmOpen(true)}
                  className={`${styles.book} ${bookStatus || activeLoad?.book ? `${styles.selected}` : "" }`}
                >
                  {bookStatus || activeLoad?.book ? "Booked" : "Book"}
                </button>
                <button
                  onClick={() => handleBidModalOpen()}
                  disabled={bookStatus || activeLoad?.book}
                  className={`${styles.bid} ${bidStatus || activeLoad?.bid ? `${styles.selected}` : "" }`}
                >
                  {bidStatus || activeLoad?.bid ? "BID PLACED" : "BID"}
                </button>
              </div>
              <div className={styles.img_div} onClick={() => setContactDetailModalOpen(true)}>
                <img src="images/call.svg" alt="" />
              </div>
            </div>
            {(bookStatus || activeLoad?.book) && <div className={styles.bid_placed}>
              <img src="images/stopwatch.svg" alt="" />
              <p>Your booking interest is sent to the Operation Team - They will get back to you with confirmation.</p>
            </div>}
            {(bidStatus || activeLoad?.bid) && <div className={styles.bid_placed}>
              <img src="images/stopwatch.svg" alt="" />
              <p>Your bidding interest is sent to the Operation Team - They will get back to you with confirmation.</p>
            </div>}

            <div className={styles.load_text}>
              <p>Load Details</p>
              <div></div>
            </div>
            <div className={styles.location_wrapper}>
              <div>
                <div className={styles.pickup}>
              <img src={"images/p_marker.svg"} height="24" width="24" alt="" />
              <p>
                {getLocationText(activeLoad, 'pickup')}
                <span> (0mi)</span>
              </p>
            </div>

            <div className={styles.pickup}>
              <img src="images/d_marker.svg" height="24" width="24" alt="" />
              <p>
                {getLocationText(activeLoad, 'dropoff')}
                <span> (0mi)</span>
              </p>
            </div>
              </div>
              <div className={styles.load_number} >
                #{activeLoad.load_number}
              </div>
            </div>


            <div className={styles.details_load_container}>
              <div>
                <div className={styles.pickupData}>
                  <img src="images/calender.svg" alt="img" width={18} height={21} />
                  <div>
                    <p className={styles.pickup_light_text}>Pick Up Date</p>
                    <p className={styles.pickup_date_text}>
                      {activeLoad?.pickup_date ? moment(activeLoad?.pickup_date).format("MMM, DD") : '-'}
                    </p>
                  </div>
                </div>
                <div className={styles.pickupData}>
                  <img src="images/calender.svg" alt="img" width={18} height={21} />
                  <div>
                    <p className={styles.pickup_light_text}>Delivery Date</p>
                    <p className={styles.pickup_date_text}>
                      {activeLoad?.delivery_date ? moment(activeLoad?.delivery_date).format("MMM, DD") : '-'}
                    </p>
                  </div>
                </div>
              </div>

              <div className={styles.right_container}>
                <div className={styles.pickupData}>
                  <img src="images/distance.svg" alt="img" width={18} height={21} />
                  <div>
                    <p className={styles.pickup_light_text}>Distance</p>
                    <p className={styles.pickup_date_text}>
                      {activeLoad?.distance ? `${Math.round(activeLoad?.distance)} mi` : '-'}
                    </p>
                  </div>
                </div>
                <div className={styles.pickupData}>
                  <img src="images/price.svg" alt="img" width={18} height={21} />
                  <div>
                    <p className={styles.pickup_light_text}>Price</p>
                    <p className={`${styles.pickup_date_text} ${styles.price}`}>
                      {activeLoad?.load_price ? fCurrency(activeLoad?.load_price) : '-'}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${styles.load_contact}`}>
              <p>Load Contact</p>
              <div></div>
            </div>

            <div className={styles.details_load_container}>
              <div>
                <div className={styles.pickupData}>
                  <div>
                    <p className={styles.pickup_light_text} style={{ marginLeft: "0px" }}>Contact</p>
                    <p className={styles.pickup_date_text} style={{ marginLeft: "0px" }}>
                      {isValidToken() ? activeLoad?.contact_name : '+XX (XXX) XXX XXXX'}
                    </p>
                  </div>
                </div>
                <div className={styles.pickupData}>
                  <div>
                    <p className={styles.pickup_light_text} style={{ marginLeft: "0px" }}>Weight</p>
                    <p className={styles.pickup_date_text} style={{ marginLeft: "0px" }}>
                      {activeLoad?.load_weight}
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.right_container}>
                <div className={styles.pickupData}>
                  <div>
                    <p className={styles.pickup_light_text} style={{ marginLeft: "0px" }}>Trailer Type</p>
                    <p className={styles.pickup_date_text} style={{ marginLeft: "0px" }}>
                      {activeLoad?.carrier_type}
                    </p>
                  </div>
                </div>
                <div className={styles.pickupData}>
                  <div>
                    <p className={styles.pickup_light_text} style={{ marginLeft: "0px" }}>Length</p>
                    <p className={styles.pickup_date_text} style={{ marginLeft: "0px" }}>
                      {(activeLoad?.load_length) || '-'}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {activeLoad?.remark && activeLoad?.remark !== '' && activeLoad?.remark !== '()' ? (
              <div>
                <p className={styles.note}>Note</p>
                <p className={styles.note_text}>
                  {activeLoad?.remark}
                </p>
              </div>
            ) : (
              ""
            )}
            <div className={styles.load_contact}>
              <p>Trip Details</p>
              <div></div>
            </div>
            <div className={styles.trip_detail_container}>
              <div>
                <div>
                  <img src="images/p_marker.svg" height="24" width="24" alt="" />
                </div>
                <div>
                  <p style={{ fontSize: "16px", fontWeight: "600" }}>
                    <span className={styles.pickupTag}>Pickup</span>
                    {getLocationText(activeLoad, 'pickup')}
                  </p>
                  <p style={{ fontSize: "14px", margin: "13px 0px 11px 10px" }}>
                    {`${getLocationText(activeLoad, 'pickup')}${activeLoad.dhp ? ` / Deadhead ${Math.round(activeLoad.dhp)}` : ''} mi`}
                  </p>
                  <p style={{ fontSize: "14px" }}>
                    {activeLoad?.pickupDateTime}
                  </p>
                  <p className={styles.trip_distance}>
                    {`Distance: ${activeLoad?.distance ? `${Math.round(activeLoad?.distance)} mi` : '-'}`}
                  </p>
                </div>
              </div>
              <div className={styles.trip_dropoff}>
                <div>
                  <img src="images/d_marker.svg" height="24" width="24" alt="" />
                </div>
                <div>
                  <p style={{ fontSize: "16px", fontWeight: "600" }}>
                    <span className={styles.dropoffTag}>Delivery</span>
                    {getLocationText(activeLoad, 'dropoff')}
                  </p>
                  <p style={{ fontSize: "14px", margin: "13px 0px 11px 10px" }}>
                    {getLocationText(activeLoad, 'dropoff')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        : ''
        }

      </div>

    </div>
  );
};

export default Dashboard;
