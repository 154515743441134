import {
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "./login.module.scss";
import { useNavigate } from "react-router-dom";
import { setSession } from "../../utils/utils";
const Signup = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [successAccount, setSuccessAccount] = useState(false);
    const [error, setError] = useState();

    const navigate=useNavigate()
    const [carrierData, setCarrierData] = useState({
        email: "",
        password: "",

    });
    const [carrierDataError, setCarrierDataError] = useState({
        email: null,
        password: null,

    });
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    useEffect(() => {
        const getToken = localStorage.getItem("accessToken");
        if (getToken) {
            navigate(`/`, { replace: true });
        }
    } , []);


    const login = async () => {
        try {

            setError(null)
            if (carrierData.email === "" || carrierData.email === null) {
                setCarrierDataError((prev) => {
                    return { ...prev, email: "Email is required" };
                });
            } else {
                let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

                if (!regex.test(carrierData.email)) {
                    setCarrierDataError((prev) => {
                        return { ...prev, email: "Enter valid email" };
                    });
                } else {
                    setCarrierDataError((prev) => {
                        return { ...prev, email: null };
                    });
                }
            }

            if (carrierData.password === "" || carrierData.password === null) {
                setCarrierDataError((prev) => {
                    return { ...prev, password: "Password is required" };
                });
            }else{
                setCarrierDataError((prev) => {
                    return { ...prev, password: null };
                })
            }
            if (

                !carrierDataError.email &&
                !carrierDataError.password

            ) {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_PATH}auth/login-public`,
                    {
                        email: carrierData.email,
                        password: carrierData.password,

                    }
                );
                // console.log(response?.data?.meta.success,'-----',response?.data?.data.token);
                setSession(response?.data?.data?.token)
                if (response?.data?.meta.success) {
                    setCarrierData({
                        email: "",
                        password: "",

                    });
                    navigate('/')
                }
            }
        } catch (error) {
            setError(error?.response?.data?.error);        }
    };
    return (
        <div className={styles.background}>
            <img
                src={"images/freightx_head.svg"}
                width={340}
                height={55}
                alt=""
            />
            <div className={styles.container}>
                <div>
                    <p>Sign in</p>

                    <div className={styles.text_field_container}>
                        <TextField
                            id="outlined-basic"
                            label="Email*"
                            type={"email"}
                            value={carrierData.email}
                            error={carrierDataError.email ? true : false}
                            InputProps={{
                                style: {
                                    borderRadius: "8px",
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    width: "100%",
                                    color: "#919EAB",
                                },
                            }}
                            helperText={
                                carrierDataError.email
                                    ? carrierDataError.email
                                    : ""
                            }
                            variant="outlined"
                            onChange={(e) =>
                                setCarrierData((prev) => {
                                    return { ...prev, email: e.target.value };
                                })
                            }
                        />
                    </div>

                    <FormControl
                        style={{ width:'100%' }}

                        variant="outlined"
                    >
                        <InputLabel
                            style={{ color: "#919EAB" ,}}
                            htmlFor="outlined-adornment-password"
                        >
                            Password*
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            style={{
                                borderRadius: "8px"
                            }}
                            value={carrierData.password}
                            error={carrierDataError.password ? true : false}
                            helperText={
                                carrierDataError.password
                                    ? carrierDataError.password
                                    : ""
                            }
                            onChange={(e) =>
                                setCarrierData((prev) => {
                                    return {
                                        ...prev,
                                        password: e.target.value,
                                    };
                                })
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                        {carrierDataError.password && (
                            <p className={styles.carrier_error}>
                                {carrierDataError.password}
                            </p>
                        )}
                     { error && (
                        <p className={styles.error}>{error}</p>
                    )}
                    </FormControl>


                    <div className={styles.forgot_pass}>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Remember me" />
                        <p onClick={()=>navigate('/forgotpassword')}>Forgot password?</p>
                    </div>
                    <button
                        onClick={() => login()}
                        className={styles.create_btn}
                    >
                        Sign in
                    </button>
                    <p className={`${styles.terms} ${styles.signin}`}>
                        You still don't have an account? Let us take you to
                        <br /> <span  onClick={()=>navigate('/signup')}>Create an account!</span>

                    </p>
                </div>
            </div>
        </div>
    );
};

export default Signup;
