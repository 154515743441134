/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./savedsearchmodal.module.scss";
import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    background: "#FFFFFF",
    borderRadius: "16px",
  },
  "& .MuiDialogActions-root": {
    background: "#FFFFFF",
    borderRadius: "16px",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      fontSize={18}
      fontWeight={700}
      sx={{ m: 0, padding: '10px 20px 0px 20px' }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function CustomizedDialogs({
  open,
  handleClose,
  setActiveLoad,
  setFilters,
  getAllLoads,
  setFiltersLoading
}) {
  const [mode, setMode] = useState('saved');
  const [loading, setLoading] = useState(false);
  const [savedData, setSavedData] = useState(null);
  const [recentData, setRecentData] = useState(null);

  useEffect(() => {
    if (open === true) {
      getData();
    }
  }, [open]);

  const closeModal = () => {
    handleClose();
    setMode('saved');
    setLoading(false);
    setSavedData(null);
    setRecentData(null);
  }

  const onSavedClick = (obj) => {
    const filters = {};
    filters.pickup = obj.pickup_city
    filters.pickup_city = obj.pickup_city
    filters.pickup_state = obj.pickup_state
    filters.pickup_lat = obj.pickup_lat
    filters.pickup_long = obj.pickup_long
    filters.dropoff = obj.dropoff_city
    filters.dropoff_city = obj.dropoff_city
    filters.dropoff_state = obj.dropoff_state
    filters.dropoff_lat = obj.dropoff_lat
    filters.dropoff_long = obj.dropoff_long
    filters.pickup_dhp = 200
    if (obj.carrier_type) {
      filters.carrier_type = obj.carrier_type
    }

    setFilters(filters);
    setFiltersLoading(true);
    getAllLoads(filters);
    setActiveLoad(null);
    closeModal();
  }

  const getData = async () => {
    setLoading(true);
    try {
      let accessToken = localStorage.getItem("accessToken");
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

      /* const savedResponse = await axios.get(
        `${process.env.REACT_APP_API_PATH}loads/get-save-search-data`
      );
      if (savedResponse?.data?.data?.searchData) {
        setSavedData(savedResponse?.data?.data?.searchData);
      } */

      const savedResponse = await axios.get(
        `${process.env.REACT_APP_API_PATH}lanes/get-all-saved-lanes`
      );
      if (savedResponse?.data?.data) {
        setSavedData(savedResponse?.data?.data);
      }
    } catch (error) {
      //
    }
    setLoading(false);
  };

  const capitalize = (val) => {
    if (val && val !== '') {
      return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
    } else {
      return '';
    }
  }

  return (
    <div>
      <BootstrapDialog
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper ": {
            minWidth: "300px",
            maxWidth: "500px",
            borderRadius: "16px",
            padding: "0px"
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={closeModal}
          className={styles.modal_header}
        >
          <button
            className={styles.tab_button}
            onClick={() => setMode('saved')}
            style={mode === 'saved' ? { borderBottom: '2px solid #000000', color: '#212B36', fontWeight: 'bold' } : {}}
          >Saved</button>
          {/* <button
            className={styles.tab_button}
            onClick={() => setMode('recent')}
            style={mode === 'recent' ? { borderBottom: '2px solid #000000', color: '#212B36', fontWeight: 'bold' } : {}}
          >Recent</button> */}
        </BootstrapDialogTitle>

        <DialogContent style={{ padding: '0px' }}>
          { loading ?
            <div className={styles.no_content}>Loading...</div>
          :
            <div className={styles.top_line}>
              { mode === 'saved' ?
                savedData && savedData.length > 0 ?
                  savedData.map((saved) => (
                    <div className={styles.loads} onClick={() => onSavedClick(saved)}>
                      <div>
                        <div>{`${saved.pickup_city || '-'} ${saved.pickup_state || '-'}`}</div>
                        <img src="/right-arrow.svg" alt="" />
                        <div>{`${saved.dropoff_city || '-'} ${saved.dropoff_state || '-'}`}</div>
                      </div>
                      { saved.carrier_type ?
                        <div>{`Trailer: ${capitalize(saved.carrier_type)}`}</div>
                      : ''
                      }
                    </div>
                  ))
                :
                  <div className={styles.no_content}>No results saved.</div>
              :
                recentData && recentData.length > 0 ?
                  recentData.map((recent) => (
                    <div className={styles.loads} onClick={() => onSavedClick(recent)}>
                      <div>
                        <div>{`${recent.pickup_city || '-'} ${recent.pickup_state || '-'}`}</div>
                        <img src="/right-arrow.svg" alt="" />
                        <div>{`${recent.dropoff_city || '-'} ${recent.dropoff_state || '-'}`}</div>
                      </div>
                      <div>Trailer: All</div>
                    </div>
                  ))
                :
                  <div className={styles.no_content}>No recent search.</div>
              }
            </div>
          }
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
