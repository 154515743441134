import {
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "./signup.module.scss";
import SignupSuccess from "../../components/signupSuccess/signupSuccess";
import { useNavigate } from "react-router-dom";

const Signup = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [successAccount, setSuccessAccount] = useState(false);
    const [error, setError] = useState();
    const navigate=useNavigate()
    let emailregex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    let mobileregex = new RegExp("\\d{10}$");

    const [carrierData, setCarrierData] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
        carrier_number: '',
        verify_code: '',
        cnfrm_password: '',
    });
    const [carrierDataError, setCarrierDataError] = useState({
        name: null,
        email: null,
        phone: null,
        password: null,
        carrier_number: null,
        verify_code: null,
        cnfrm_password: null,
    });
    useEffect(() => {
        const getToken = localStorage.getItem("accessToken");
        if (getToken) {
            navigate(`/`, { replace: true });
        }
    } , []);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const getVerificationCode = async () => {

        if((carrierData.phone!==''||carrierData.phone!==null)&&mobileregex.test(carrierData.phone)){
            try {
                await axios.post(
                    `${process.env.REACT_APP_API_PATH}auth/verification`,
                    { phone: carrierData.phone }
                );
                setError(null)
            } catch(error) {
                // console.log('catch error =====>', error);
                // setError(error)
                setError("Enter valid phone number")
            }
        }else{

            setError("Enter valid phone number")
        }
    };
    const createAccount = async () => {
        try {
            if (carrierData.cnfrm_password != carrierData.password) {
                setError("Password donot match");
            }else{
                setError(null)
            }
            if (carrierData.name === "" || carrierData.name === null) {
                setCarrierDataError((prev) => {
                    return { ...prev, name: "Carrier name is required" };
                });
            }else{
                setCarrierDataError((prev) => {
                    return { ...prev, name: null };
                });
            }
            if (carrierData.email === "" || carrierData.email === null) {
                setCarrierDataError((prev) => {
                    return { ...prev, email: "Email is required" };
                });
            } else {
                // let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

                if (!emailregex.test(carrierData.email)) {
                    setCarrierDataError((prev) => {
                        return { ...prev, email: "Enter valid email" };
                    });
                } else {
                    setCarrierDataError((prev) => {
                        return { ...prev, email: null };
                    });
                }
            }
            if (carrierData.phone === "" || carrierData.phone === null) {
                setCarrierDataError((prev) => {
                    return { ...prev, phone: "Phone is required" };
                });
            } else {
                if (!mobileregex.test(carrierData.phone)) {
                    setCarrierDataError((prev) => {
                        return { ...prev, phone: "Enter valid phone number" };
                    });
                } else {
                    setCarrierDataError((prev) => {
                        return { ...prev, phone: null };
                    });
                }
            }
            if (
                carrierData.carrier_number === "" ||
                carrierData.carrier_number === null
            ) {
                setCarrierDataError((prev) => {
                    return {
                        ...prev,
                        carrier_number: "Carrier number is required",
                    };
                });
            }else{
                setCarrierDataError((prev) => {
                    return { ...prev, carrier_number: null };
                });
            }
            if (
                carrierData.verify_code === "" ||
                carrierData.verify_code === null
            ) {
                setCarrierDataError((prev) => {
                    return {
                        ...prev,
                        verify_code: "Verification code is required",
                    };
                });
            }else{
                setCarrierDataError((prev) => {
                    return {
                        ...prev,
                        verify_code: null,
                    };
                });
            }
            if (
                carrierData.cnfrm_password === "" ||
                carrierData.cnfrm_password === null
            ) {
                setCarrierDataError((prev) => {
                    return {
                        ...prev,
                        cnfrm_password: "Confirm password is required",
                    };
                });
            }else{
                setCarrierDataError((prev) => {
                    return {
                        ...prev,
                        cnfrm_password: null,
                    };
                });
            }
            if (carrierData.password === "" || carrierData.password === null) {
                setCarrierDataError((prev) => {
                    return { ...prev, password: "Password is required" };
                });
            }else{
                setCarrierDataError((prev) => {
                    return { ...prev, password:null };
                });
            }
            if (
                !carrierDataError.name &&
                !carrierDataError.phone &&
                !carrierDataError.email &&
                !carrierDataError.password &&
                !carrierDataError.cnfrm_password &&
                !carrierDataError.carrier_number &&
                !carrierDataError.verify_code&&carrierData.cnfrm_password === carrierData.password
            ) {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_PATH}auth/signup-public`,
                    {
                        name: carrierData.name,
                        phone: carrierData.phone,
                        email: carrierData.email,
                        password: carrierData.password,
                        carrier_number: carrierData.carrier_number,
                        verification_code: carrierData.verify_code,
                    }
                );
                // console.log(response?.data?.meta.success);
                if (response?.data?.meta.success) {
                    setCarrierData({
                        name: '',
                        email: '',
                        phone: '',
                        password: '',
                        carrier_number: '',
                        verify_code: '',
                        cnfrm_password: '',
                    });
                    setSuccessAccount(true)
                }

            }
        } catch (error) {
            // console.log(error);
        }
    };
    return !successAccount ? (
        <div className={styles.background}>
            <img
                src={"images/freightx_head.svg"}
                width={340}
                height={55}
                alt=""
            />
            <div className={styles.container}>
                <div>
                    <p>Create your account</p>
                    <div className={styles.text_field_container}>
                        <TextField
                            id="outlined-basic"
                            label="Carrier name"
                            variant="outlined"
                            value={carrierData.name}
                            error={carrierDataError.name ? true : false}
                            InputProps={{
                                style: {
                                    borderRadius: "8px",
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    width: "100%",
                                    color: "#919EAB",
                                },
                            }}
                            onChange={(e) =>
                                setCarrierData((prev) => {
                                    return { ...prev, name: e.target.value };
                                })
                            }
                            helperText={
                                carrierDataError.name
                                    ? carrierDataError.name
                                    : ""
                            }
                        />
                    </div>
                    <div className={styles.text_field_container}>
                        <TextField
                            id="outlined-basic"
                            error={carrierDataError.phone ? true : false}
                            label="Phone*"
                            variant="outlined"
                            type={"number"}
                            InputLabelProps={{
                                style: {
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    width: "100%",
                                    color: "#919EAB",
                                },
                            }}
                            InputProps={{
                                style: {
                                    borderRadius: "8px",
                                },
                            }}
                            inputProps={{ maxLength: 10 }}
                            onChange={(e) =>
                                setCarrierData((prev) => {
                                    return {
                                        ...prev,
                                        phone: e.target.value.slice(0, 10),
                                    };
                                })
                            }
                            value={carrierData.phone}
                            helperText={
                                carrierDataError.phone
                                    ? carrierDataError.phone
                                    : ""
                            }
                        />
                    </div>
                    <div className={styles.text_field_container}>
                        <TextField
                            id="outlined-basic"
                            label="Email*"
                            type={"email"}
                            value={carrierData.email}
                            error={carrierDataError.email ? true : false}
                            InputProps={{
                                style: {
                                    borderRadius: "8px",
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    width: "100%",
                                    color: "#919EAB",
                                },
                            }}
                            helperText={
                                carrierDataError.email
                                    ? carrierDataError.email
                                    : ""
                            }
                            variant="outlined"
                            onChange={(e) =>
                                setCarrierData((prev) => {
                                    return { ...prev, email: e.target.value };
                                })
                            }
                        />
                    </div>
                    <div className={styles.text_field_container}>
                        <TextField
                            id="outlined-basic"
                            className={styles.txt_field}
                            label="Motor carrier number* "
                            type={"text"}
                            variant="outlined"
                            value={carrierData.carrier_number}
                            error={
                                carrierDataError.carrier_number ? true : false
                            }
                            helperText={
                                carrierDataError.carrier_number
                                    ? carrierDataError.carrier_number
                                    : ""
                            }
                            InputProps={{
                                style: {
                                    borderRadius: "8px",
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    width: "100%",
                                    color: "#919EAB",
                                },
                            }}
                            onChange={(e) =>
                                setCarrierData((prev) => {
                                    return {
                                        ...prev,
                                        carrier_number: e.target.value,
                                    };
                                })
                            }
                        />
                    </div>

                    <FormControl
                        // sx={{ m: 1, width: "25ch" }}
                        style={{ marginBottom: "20px" }}
                        variant="outlined"
                    >
                        <InputLabel
                            style={{ color: "#919EAB" }}
                            htmlFor="outlined-adornment-password"
                        >
                            6 Digit verification code*
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={"text"}
                            style={{
                                borderRadius: "8px",
                            }}
                            value={carrierData?.verify_code}
                            endAdornment={
                                <InputAdornment position="end">
                                    <div
                                        className={styles.get_code}
                                        onClick={() => getVerificationCode()}
                                    >
                                        Get code
                                    </div>
                                </InputAdornment>
                            }
                            error={carrierDataError.verify_code ? true : false}
                            helperText={
                                carrierDataError.verify_code
                                    ? carrierDataError.verify_code
                                    : ""
                            }
                            label="verification_code"
                            onChange={(e) =>
                                setCarrierData((prev) => {
                                    return {
                                        ...prev,
                                        verify_code: e.target.value,
                                    };
                                })
                            }
                        />
                        {carrierDataError.verify_code && (
                            <p className={styles.carrier_error}>
                                {carrierDataError.verify_code}
                            </p>
                        )}
                    </FormControl>
                    <FormControl
                        // sx={{ }}
                        style={{ marginBottom: "20px" }}
                        variant="outlined"
                    >
                        <InputLabel
                            style={{ color: "#919EAB" }}
                            htmlFor="outlined-adornment-password"
                        >
                            Password*
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            style={{
                                borderRadius: "8px",
                            }}
                            value={carrierData.password}
                            error={carrierDataError.password ? true : false}
                            helperText={
                                carrierDataError.password
                                    ? carrierDataError.password
                                    : ""
                            }
                            onChange={(e) =>
                                setCarrierData((prev) => {
                                    return {
                                        ...prev,
                                        password: e.target.value,
                                    };
                                })
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                        {carrierDataError.password && (
                            <p className={styles.carrier_error}>
                                {carrierDataError.password}
                            </p>
                        )}
                    </FormControl>
                    <FormControl
                        // sx={{ }}
                        variant="outlined"
                    >
                        <InputLabel
                            style={{ color: "#919EAB" }}
                            htmlFor="outlined-adornment-password"
                        >
                            Confirm password*
                        </InputLabel>
                        <OutlinedInput
                            error={
                                carrierDataError.cnfrm_password ? true : false
                            }
                            helperText={
                                carrierDataError.cnfrm_password
                                    ? carrierDataError.cnfrm_password
                                    : ""
                            }
                            value={carrierData.cnfrm_password}
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            style={{ borderRadius: "8px" }}
                            onChange={(e) =>
                                setCarrierData((prev) => {
                                    return {
                                        ...prev,
                                        cnfrm_password: e.target.value,
                                    };
                                })
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Confirm password*"
                        />
                        {carrierDataError.cnfrm_password && (
                            <p className={styles.carrier_error}>
                                {carrierDataError.cnfrm_password}
                            </p>
                        )}
                    </FormControl>
                    { error && (
                        <p className={styles.error}>{error}</p>
                    )}
                    <p className={styles.terms}>
                        By clicking on "Create an account" button, you agree to
                        our <span onClick={()=>{}}>Terms of Service</span> and{" "}
                        <span onClick={()=>navigate('/')}>Privacy Policy</span>.{" "}
                    </p>
                    <button
                        onClick={() => createAccount()}
                        className={styles.create_btn}
                    >
                        Create an account
                    </button>
                    <p className={`${styles.terms} ${styles.signin}`}>
                        Already on board? Let us take you to{" "}
                        <span onClick={()=>navigate('/login')}>Sign In!</span>
                    </p>
                </div>
            </div>
        </div>
    ) : (
        <SignupSuccess />
    );
};

export default Signup;
