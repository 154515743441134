import { TextField } from "@mui/material";
import React from "react";
import styles from "./forgotPassSuccess.module.scss";
import { useNavigate } from "react-router-dom";
const ForgotPassSuccess = () => {
    const navigate=useNavigate()
    return (
        <div className={styles.background}>
            <img
                src={"images/freightx_head.svg"}
                width={340}
                height={55}
                alt=""
            />
            <div className={styles.container}>
                <div>
                    <img src="images/check.svg" width={40} height={40} alt=""/>
                    <p>
                       Your account password is reset<br/> successfully!
                    </p>
                     <p className={styles.footer_text}>Go to <span onClick={()=>navigate('/login')}>Sign In!</span></p>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassSuccess;
