import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  InputAdornment,
  TextField
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./bidmodal.module.scss";
import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    background: "#FFFFFF",
    borderRadius: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    background: "#FFFFFF",
    borderRadius: "16px",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      fontSize={18}
      fontWeight={700}
      sx={{ m: 0, p: 2 }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function CustomizedDialogs({
  open,
  setOpen,
  handleClickOpen,
  handleClose,
  setBidStatus,
  load_id,
  getAllLoads
}) {
  // const [open, setOpen] = React.useState(false);
  // const [bid, setBid] = React.us÷eState();
  const [bidData, setBidData] = React.useState({
    bid_cost: null,
    remarks: null,
  });
  const createBid = async (bidCost, remarks = '', load_id) => {
    try {
      let accessToken = localStorage.getItem("accessToken");

      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}loads/set-bid`,
        {
          remarks: remarks,
          bid_cost: bidCost ? parseInt(bidCost) : 0,
          load_id: load_id ? load_id : "",
        }
      );
      if (response?.data?.meta?.success) {
        setBidStatus(true);
        getAllLoads();
      }

    } catch (error) {
      //
    }
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper ": {
            width: "500px",
            borderRadius: "16px",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Place Your BID
        </BootstrapDialogTitle>
        <DialogContent>
          <div className={styles.top_line}>
            <TextField
              label={bidData.bid_cost ? `Offer a price*` : ""}
              id="outlined-start-adornment"
              placeholder="Offer a price*"
              sx={{}}
              onChange={(e) => {
                setBidData((prev) => {
                  return {
                    ...prev,
                    bid_cost: e.target.value,
                  };
                });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="outlined-multiline-static"
              label="Note"
              multiline
              onChange={(e) =>
                setBidData((prev) => {
                  return { ...prev, remarks: e.target.value };
                })
              }
              rows={6}
              variant="outlined"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className={styles.bid_btn}
            onClick={() => {
              createBid(bidData.bid_cost, bidData.remarks, load_id);
              handleClose();
            }}
          >
            Place a BID
          </button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
