import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  InputAdornment,
  TextField
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./confirmationmodal.module.scss";
import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    background: "#FFFFFF",
    borderRadius: "16px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    background: "#FFFFFF",
    borderRadius: "16px",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      fontSize={18}
      fontWeight={700}
      sx={{ m: 0, p: 2 }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function CustomizedDialogs({
  open,
  handleClose,
  confirmationText,
  onConfirm,
  confirmText,
  cancelText
}) {

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper ": {
            width: "500px",
            borderRadius: "16px",
          },
        }}
      >
        {/* <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Book Load
        </BootstrapDialogTitle> */}
        <DialogContent>
          <div className={styles.top_line}>
          <div>
            <img src='/images/book-load-confirm.svg'  alt=""/>
          </div>
          <div>
          {confirmationText}
          </div>
          <div>
            <button className={styles.cancel_btn} onClick={() => { handleClose(); }} style={{marginRight: '10px'}}>
              {cancelText}
            </button>
            <button className={styles.bid_btn} onClick={() => { onConfirm(); handleClose(); }}>
              {confirmText}
            </button>
          </div>
          </div>
        </DialogContent>
        {/* <DialogActions>
        </DialogActions> */}
      </BootstrapDialog>
    </div>
  );
}
