import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Visibility, VisibilityOff, Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent
} from "@mui/material";
import styles from "./loginModal.module.scss";
import axios from "axios";
import { setSession } from "../../utils/utils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function CustomizedDialogs({ open, handleClose }) {
  const [showPassword, setShowPassword] = React.useState(false);
  // const [successAccount, setSuccessAccount] = useState(false);
  const [error, setError] = useState()
  const [carrierData, setCarrierData] = React.useState({
    email: "",
    password: "",
  });
  const [carrierDataError, setCarrierDataError] = useState({
    email: null,
    password: null,
  });
  const navigate = useNavigate();

  const login = async () => {
    try {
      setError(null);
      if (carrierData.email === "" || carrierData.email === null) {
        setCarrierDataError((prev) => {
          return { ...prev, email: "Email is required" };
        });
      } else {
        let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

        if (!regex.test(carrierData.email)) {
          setCarrierDataError((prev) => {
            return { ...prev, email: "Enter valid email" };
          });
        } else {
          setCarrierDataError((prev) => {
            return { ...prev, email: null };
          });
        }
      }

      if (carrierData.password === "" || carrierData.password === null) {
        setCarrierDataError((prev) => {
          return { ...prev, password: "Password is required" };
        });
      } else {
        setCarrierDataError((prev) => {
          return { ...prev, password: null };
        });
      }

      if (!carrierDataError.email && !carrierDataError.password) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_PATH}auth/login-public`,
          {
            email: carrierData.email,
            password: carrierData.password,
          }
        );

        setSession(response?.data?.data?.token);
        if (response?.data?.meta.success) {
          setCarrierData({
            email: "",
            password: "",
          });
          handleClose();
        }
      }
    } catch (error) {
      setError(error?.response?.data?.error);
    }
  };
  return (
    <div className={styles.complete_wrapper}>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={styles.modal_container}
        sx={{ '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper ': { width: "500px", height: "480px", borderRadius: "16px" } }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
        </BootstrapDialogTitle>
        <DialogContent className={styles.modal_head} >
          <div className={styles.container}>
            <div>
              <p>Sign in</p>

              <div className={styles.text_field_container}>
                <TextField
                  id="outlined-basic"
                  label="Email*"
                  type={"email"}
                  value={carrierData.email}
                  error={
                    carrierDataError.email ? true : false
                  }
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "100%",
                      color: "#919EAB",
                    },
                  }}
                  helpertext={
                    carrierDataError.email
                      ? carrierDataError.email
                      : ""
                  }
                  variant="outlined"
                  onChange={(e) =>
                    setCarrierData((prev) => {
                      return {
                        ...prev,
                        email: e.target.value,
                      };
                    })
                  }
                />
              </div>

              <FormControl
                // sx={{ }}
                style={{ width: "100%" }}
                variant="outlined"
              >
                <InputLabel
                  style={{ color: "#919EAB", width: '100%' }}
                  htmlFor="outlined-adornment-password"
                >
                  Password*
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  style={{
                    borderRadius: "8px",
                  }}
                  value={carrierData.password}
                  error={
                    carrierDataError.password ? true : false
                  }
                  helpertext={
                    carrierDataError.password
                      ? carrierDataError.password
                      : ""
                  }
                  onChange={(e) =>
                    setCarrierData((prev) => {
                      return {
                        ...prev,
                        password: e.target.value,
                      };
                    })
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={
                          () => setShowPassword((show) => !show)
                        }
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {carrierDataError.password && (
                  <p className={styles.carrier_error}>
                    {carrierDataError.password}
                  </p>
                )}

                {error && (
                  <p className={styles.error}>{error}</p>
                )}
              </FormControl>
              <div className={styles.forgot_pass}>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Remember me"
                />
                <p onClick={() => navigate("/forgotpassword")}>
                  Forgot password?
                </p>
              </div>
              <button
                onClick={() => login()}
                className={styles.create_btn}
              >
                Sign in
              </button>
              <p className={`${styles.terms} ${styles.signin}`}>
                You still don't have an account? Let us take you
                to
                <br />{" "}
                <span onClick={() => navigate("/signup")}>
                  Create an account!
                </span>
              </p>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
