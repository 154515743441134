import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./signupSuccess.module.scss";

const SignupSuccess = () => {
    const navigate=useNavigate()
    return (
        <div className={styles.background}>
            <img
                src={"images/freightx_head.svg"}
                width={340}
                height={55}
                alt=""
            />
            <div className={styles.container}>
                <div>
                    <img src="images/check.svg" width={40} height={40} alt=""/>
                    <p>
                        Congrats!
                        <br />
                        Your account is created
                        <br /> successfully.
                    </p>
                    <button
                        onClick={()=>navigate('/login')}
                        style={{
                            background: '#212b36',
                            borderRadius: '8px',
                            border: 'none',
                            fontWeight: '600',
                            fontSize: '15px',
                            lineHeight: '22px',
                            padding: '13px 30px',
                            color: '#ffffff',
                            cursor: 'pointer',
                        }}
                    >Login</button>
                </div>
            </div>
        </div>
    );
};

export default SignupSuccess;
