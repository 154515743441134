// import logo from "./logo.svg";
import "./App.css";
import {
	// BrowserRouter,
	Route,
	Routes,
	useNavigate,
} from "react-router-dom";
import Signup from './containers/Signup/signup'
import Login from './containers/Login/login'
import ForgotPass from './containers/ForgotPass/forgotPass'
import Dashboard from "./containers/DashboardNew/dashboard";
import { useEffect } from "react";
import { isValidToken } from './utils/utils';

function App() {
	const navigate = useNavigate();
	useEffect(() => {
		const accessToken = localStorage.getItem("accessToken")
		if (accessToken) {
			if (!isValidToken(accessToken)) {
				localStorage.removeItem("accessToken");
				navigate(`/login`, { replace: true });
			}
		} else {
			navigate(`/`, { replace: true });
		}
	}, []);

	return (
		<Routes>
			<Route exact path="/signup" element={<Signup />} />
			<Route exact path="/login" element={<Login />} />
			<Route exact path="/forgotpassword" element={<ForgotPass />} />
			<Route exact path="/" element={<Dashboard />} />
		</Routes>
	)
}

export default App;
